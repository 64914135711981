import AuthDefault from "app/components/Template/AuthDefault";
import AuthEnsinger from "app/components/Template/AuthEnsinger";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export function AuthPage() {
  if(process.env.REACT_APP_BRAND === 'ensinger') {
    return <AuthEnsinger />
  }
  return (
    <AuthDefault/>
  );
}
